import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Spin, Typography } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { supabase } from '../supabase/supabase-client'

export default function ResetPassword() {
  const [step, setStep] = useState('init')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        setStep('recovery')
      } else {
        setStep('expired')
      }
    })
  }, [])

  const onFinish = (values: any) => {
    setLoading(true)
    supabase.auth.updateUser({ password: values.password })
      .then(({ data, error }) => {
        if (error) {
          setStep('error')
          setLoading(false)
        } else {
          setStep('success')
          setLoading(false)
        }
      })
  }

  const onFinishFailed = (errorInfo: any) => {
    setStep('error')
  }

  let content = <Spin />

  if (step === 'expired') {
    content = <Typography.Text style={{ fontSize: 16 }}>Wrong link or the password recovery link is expired.</Typography.Text>
  } else if (step === 'error') {
    content = <Typography.Text>An error occurred, please try again.</Typography.Text>
  } else if (step === 'success') {
    supabase.auth.signOut()

    content = <Typography.Text style={{ fontSize: 16 }}>
      Password changed! You can now login your Talkalot account with your new password.
    </Typography.Text>
  } else if (step === 'recovery') {
    content = <>
      <Typography.Title level={3}>Change Your Password</Typography.Title>
      <Typography.Text>Enter your new password below to change it.</Typography.Text>
      <Form
        style={{ marginTop: 20 }}
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
      >
        <Form.Item
          name='password'
          rules={[
            { required: true, message: 'Please enter your new password' },
            { min: 6, message: 'Password should be at least 6 characters' },
          ]}
        >
          <Input.Password
            placeholder='New password'
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading}>
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </>
  }

  return (
    <Row style={{ marginTop: 50, justifyContent: 'center', textAlign: 'center' }}>
      <Col xs={16} sm={10} md={8} lg={6} xl={4}>
        {content}
      </Col>
    </Row>
  )
}
