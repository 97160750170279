import React, { useEffect, useState } from 'react'
import { Col, Row, Spin, Typography } from 'antd'
import { supabase } from '../supabase/supabase-client'

export default function ConfirmAccount() {
  const [step, setStep] = useState('init')

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        setStep('confirmed')
      } else {
        setStep('error')
      }
    })
  }, [])

  let content = <Spin />
  if (step === 'error') {
    content = <Typography.Text style={{ fontSize: 16 }}>Wrong or expired link.</Typography.Text>
  } else if (step === 'confirmed') {
    supabase.auth.signOut()

    content = <Typography.Text style={{ fontSize: 16 }}>
      Your account has been confirmed! You can now login your Talkalot account.
    </Typography.Text>
  }

  return (
    <Row style={{ marginTop: 50, justifyContent: 'center', textAlign: 'center' }}>
      <Col xs={16} sm={10} md={8} lg={6} xl={4}>
        {content}
      </Col>
    </Row>
  )
}
