import React, { useEffect } from 'react'
import { supabase } from '../supabase/supabase-client'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Spin } from 'antd'

export default function Auth() {
  const navigate = useNavigate()


  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      if (_event === 'PASSWORD_RECOVERY') {
        navigate('/auth/reset-password')
      } else if (_event === 'SIGNED_IN') {
        navigate('/auth/confirm-account')
      }

      console.log(_event)
    })
  }, [navigate])

  return (
    <Row style={{ marginTop: 50, justifyContent: 'center', textAlign: 'center' }}>
      <Col xs={16} sm={10} md={8} lg={6} xl={4}>
        <Spin />
      </Col>
    </Row>
  )
}