import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Auth from './routes/Auth'
import ErrorPage from './error-page'
import ResetPassword from './routes/reset-password'
import ConfirmAccount from './routes/confirm-account'

const router = createBrowserRouter([
  {
    path: '/',
    element: <div></div>,
    errorElement: <ErrorPage />,
  },
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/auth/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/auth/confirm-account',
    element: <ConfirmAccount />,
  },
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
